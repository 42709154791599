.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* Remove blue outline and focus globally */
*:focus, *:active, *:focus-visible {
  outline: none !important; /* Force removal */
  box-shadow: none !important; /* Remove shadows */
}

/* Remove tap highlight for mobile devices */
* {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none; /* Prevent additional interactions on touch */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
