/* Default styles for desktop */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: rgb(2, 115, 64); /* Blue theme */
    color: white;
  }
  
  .navbar-logo {
    width: 30px; /* Adjust size as needed */
    height: auto;
    margin-right: 10px; /* Spacing between logo and text */
  }
  
  .navbar-brand {
    display: flex;
    align-items: center; /* Vertically centers the logo and brand name */
  }
  
  .navbar-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  
  .navbar-center {
    flex: 1;
    text-align: center;
  }
  
  .navbar-options {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .navbar-options li {
    font-size: 18px;
  }
  
  .navbar-options li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .navbar-options li a:hover {
    color: #efefed; /* A lighter color for hover effect */
  }
  
  /* Logout button styling */
  .navbar-logout {
    margin-left: auto;
  }
  
  .logout-btn {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .logout-btn:hover {
    color: #efefed;
  }
  
  .logout-btn svg {
    margin-right: 5px;
  }
  
  /* Mobile-specific styles */
  .navbar-mobile {
    display: none; /* Initially hidden */
    padding: 10px;
    background-color: rgb(2, 115, 64); /* Blue theme */
    color: white;
    justify-content: space-between; /* Space between logo and button */
    align-items: center; /* Vertically center */
  }
  
  .navbar-brand-mobile {
    display: flex;
    align-items: center;
  }
  
  .categories-btn {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Modal Styles for Mobile View */
  .categories-modal {
    position: fixed;
    top: 60px; /* Below the navbar */
    left: 10px; /* Align modal to left */
    background-color: white;
    color: black;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80%; /* Keep modal width responsive */
    max-width: 300px; /* Maximum width for the modal */
  }
  
  .categories-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .categories-content ul li {
    margin: 10px 0;
    text-align: left; /* Align text to left */
  }
  
  .categories-content ul li a,
  .categories-content ul li button {
    text-decoration: none;
    color: black;
    display: block;
    padding: 10px;
    background-color: transparent;
    border: none;
    width: 100%;
  }
  
  .categories-content ul li a:hover,
  .categories-content ul li button:hover {
    background-color: #f1f1f1;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .navbar {
      display: none; /* Hide desktop navbar in mobile */
    }
  
    .navbar-mobile {
      display: flex; /* Show mobile navbar */
      
    }
    .navbar-title {
      font-size: 11px;
      
    }
    .navbar-logo {
      width: 24px; /* Adjust size as needed */
     
    }
  }
  