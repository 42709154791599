/* Container and Title */
.summary-container {
    width: 60%;
    margin: 20px auto;
    font-family: "Poppins", sans-serif;
  }
  
  .summary-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Box Styling */
  .summary-box {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    font-size: 16px;
   
    color: #000000;
    white-space: pre-wrap;
    transition: all 0.3s ease;
    height: 400px; /* Set a fixed height for the box */
    overflow: auto; /* Allows scroll if content overflows */
    text-align: left;
  }
  
  .summary-text {
    white-space: pre-wrap;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }
  
  /* Editing Mode Styling */
  .summary-edit-textarea {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    border-radius: 10px;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    color: inherit;
    outline: none;
    resize: none;
  }
  
  /* Button Group */
  .summary-button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .summary-button {
    background-color: #ffffff;
    color: rgb(2, 115, 64);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid;
    border-color: rgb(2, 115, 64);
    cursor: pointer;
    font-size: 16px;
  }
  
  .summary-button:hover {
    background-color: rgb(2, 115, 64);
    color: #ffffff;
  }
  @media screen and (max-width: 768px) {
    .summary-container {
      width: 80%; /* Increase the width on smaller screens */
    }
  
    .summary-box {
      height: 250px; /* Reduce the height for smaller screens */
      padding: 15px; /* Less padding on mobile */
    }
  
    .summary-button-group {
      flex-direction: column; /* Stack the buttons vertically */
      gap: 15px; /* Increase gap between buttons */
    }
  
    .summary-button {
      padding: 12px 25px; /* Adjust button padding for mobile */
      font-size: 14px; /* Slightly smaller font for mobile */
    }
  }
  
  @media screen and (max-width: 480px) {
    .summary-container {
      width: 90%; /* Take almost the full width on very small screens */
    }
  
    .summary-box {
      height: 350px; /* Further reduce height */
      padding: 10px; /* Smaller padding */
      font-size: 12px;
    }
  
    .summary-button-group {
      gap: 10px; /* Adjust button gap for very small screens */
    }
  
    .summary-button {
      padding: 10px 20px; /* Smaller padding */
      font-size: 14px; /* Smaller font size for mobile */
    }
  
    .summary-title {
      font-size: 18px; /* Adjust title font size for small screens */
    }
  }

  