/* Loader container that will be centered using grid */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center; /* This is the key part that centers the loader */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure loader is on top of other content */
}

/* Loader spinning circle */
.loader {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid green; /* Green color for spinning part */
  border-radius: 50%; /* Circular loader */
  width: 25px; /* Default width for desktop */
  height: 25px; /* Default height for desktop */
  animation: spin 1s linear infinite;
}

/* Spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .loader {
    width: 20px; /* Smaller loader for mobile */
    height: 20px; /* Smaller loader for mobile */
  }
}
