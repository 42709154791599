.trip-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
  
  .tab-bar {
    display: flex;
    justify-content: center;
    
    border-radius: 10px;
    padding: 10px;
    width: 80%;
  }
  
  .tab-bar button {
    background-color: #d1f6d1; /* Default inactive background */
    border: none;
    font-size: 16px;
    color: green; /* Default inactive text color */
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
    display: flex; /* Enable flex layout */
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    text-align: center; 
  }
  .tab-bar button.acyive{
    background-color: #d1f6d1; /* Light green */
    color: green;
  }
  
  .tab-bar button:not(:last-child) {
    margin-right: 20px;
  }
  
  /* Ongoing Tab (Red text with light red background when active) */
  .tab-bar button.active.ongoing {
    background-color: #ffe6e6; /* Light red */
    color: red;
  }
  
  /* Current Tab (Yellow text with light yellow background when active) */
  .tab-bar button.active.upcoming {
    background-color: #fdf5da; /* Light yellow */
    color: orange;
  }
  
  /* Completed Tab (Green text with light green background when active) */
  .tab-bar button.active.completed {
    background-color: #d1f6d1; /* Light green */
    color: green;
  }
  .tab-bar button.active.all {
    background-color: #b6e1ff; /* Light green */
    color: rgb(0, 63, 131);
  }
  /* Default inactive background and text color for non-active state */
  .tab-bar button:not(.active) {
    background-color: #e2e5e8; /* Light blue when inactive */
    color: #524f4f;
  }
  
  .tab-content {
    margin-top: 20px;
    width: 100%;
  }
  @media (max-width: 768px) {
    .tab-bar {
        justify-content: flex-start; /* Aligns buttons to the left */
        overflow-x: auto; /* Enables horizontal scrolling */
        width: 100%; /* Full width for mobile */
        max-width: none; /* No max-width restriction */
    }
    .trip-tabs{
      padding: 30px 10px;
    }
    .tab-bar button {
        min-width: 100px; /* Optional: Set minimum width for buttons */
        height: 35px;
        font-size: 12px;
        padding: 05px 10px;
    }
}
