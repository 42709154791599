.login-box {
    width: 300px;
    padding: 20px;
    margin: 120px auto;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect */
    border-radius: 8px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
    font-weight: bold;
    color: #333;
  }
  
  .input-group input,.input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: rgb(2, 115, 64); /* Blue button */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: rgb(1, 92, 51); /* Darker blue on hover */
  }
  .message-box {
    padding: 10px; /* Padding for message box */
    margin-bottom: 15px; /* Space below message box */
    border-radius: 4px; /* Rounded corners */
    text-align: center; /* Center the text */
}

.message-box.success {
    background-color: #d4edda; /* Light green background */
    color: #155724; /* Dark green text */
    border: 1px solid #c3e6cb; /* Border for success */
}

.message-box.error {
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Border for error */
}