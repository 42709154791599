.popbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the box */
    padding: 20px 30px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
    max-width: 400px; /* Optional: limit max width */
    width: auto;
  }
  
  .popbox.success {
    background-color: #4caf50; /* Green for success */
  }
  
  .popbox.error {
    background-color: #f44336; /* Red for error */
  }
  
  .popbox.info {
    background-color: #2196f3; /* Blue for info */
  }
  
  .popbox p {
    margin: 0;
  }
  
  /* Media Query for mobile responsiveness */
  @media (max-width: 768px) {
    .popbox {
      padding: 15px 20px; /* Reduce padding for smaller screens */
      font-size: 14px; /* Smaller font size on mobile */
      max-width: 80%; /* Limit the width on mobile devices */
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
  /* For very small devices like mobile in portrait mode */
  @media (max-width: 480px) {
    .popbox {
      max-width: 90%; /* Further limit the width on very small screens */
      padding: 12px 15px; /* Reduce padding further */
      font-size: 12px; /* Adjust font size for readability */
    }
  }
  