/* Parent Class for Form */
.form-to-pdf {
    max-width: 500px; /* Set a smaller maximum width for the form */
    margin: 40px auto; /* Center the form */
    padding: 20px; /* Add padding inside the form */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
    background-color: #ffffff; /* White background color for the form */
}

/* Form Elements */
.form-to-pdf h2 {
    text-align: center; /* Center the title */
    color: #333; /* Dark text color */
}

.form-to-pdf label {
    font-weight: bold; /* Make labels bold */
    margin-bottom: 5px; /* Space between label and input */
    display: block; /* Ensure labels are block-level */
    text-align: left; /* Align label text to the left */
}

.form-to-pdf input[type="text"],
.form-to-pdf input[type="date"],
.form-to-pdf input[type="email"],  /* Added for email */
.form-to-pdf input[type="password"], /* Added for password */
.form-to-pdf input[type="number"],
.form-to-pdf select {
    width: 100%; /* Full width for inputs */
    padding: 10px; /* Padding inside inputs */
    margin-bottom: 20px; /* Space between inputs */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Slightly rounded corners */
    font-size: 16px; /* Font size for inputs */
    box-sizing: border-box; /* Include padding and border in element's total width */
}

/* Select Input Styling without Arrow */
.form-to-pdf select {
    background-color: #f9f9f9; /* Light background for select inputs */
    border: 1px solid #ccc; /* Light border */
    padding: 10px; /* Padding inside select */
    appearance: none; /* Remove default arrow */
}

/* Button Styling */
.form-to-pdf button {
    width: 100%; /* Full-width button */
    padding: 12px; /* Padding inside button */
    background-color: rgb(2, 115, 64); /* Blue background color */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size for button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Button Hover Effect */


/* Textarea Styling */
.form-to-pdf textarea {
    width: 100%; /* Full width for textarea */
    padding: 10px; /* Padding inside textarea */
    margin-bottom: 20px; /* Space below textarea */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size for textarea */
    min-height: 60px; /* Minimum height for textarea */
    max-height: 200px; /* Maximum height for textarea */
    overflow-y: auto; /* Scroll if content exceeds max height */
    box-sizing: border-box; /* Include padding and border in element's total width */
}

/* Expandable Textarea on Input */
.form-to-pdf textarea:focus {
    outline: none; /* Remove outline on focus */
    border-color: #007bff; /* Change border color on focus */
}

/* Resort Option Styling */
.form-to-pdf .resort-option {
    margin-bottom: 30px; /* Space below each resort option */
    padding: 15px; /* Padding inside the resort option */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    background-color: #f1f1f1; /* Light background for resort options */
}
.form-to-pdf .add-resort-button {
    background-color: #007bff; /* White background */
    /* Blue border */
    color: #ffffff; /* Blue text color */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    padding: 10px; /* Padding for button */
    width: auto; /* Adjust width */
    margin: 10px 0 10px 0; /* Center the button */
    display: block; /* Center block */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

/* Remove Resort Button Styling */
.form-to-pdf .remove-resort-button {
    background-color: #ffffff; /* White background */
    border: 2px solid #ff0000; /* Red border */
    font-size: 14px;
    color: #ff0000; /* Red text color */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    padding: 10px; /* Padding for button */
    width: auto; /* Adjust width */
    margin: 10px 0 10px 0; /* Center the button */
    display: block; /* Center block */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

/* Submit Button Styling */
.form-to-pdf .submit-button {
    width: 80%; /* Reduced width */
    padding: 10px; /* Reduced padding */
    margin: 20px auto; /* Center the button */
    display: block; /* Center block */
}
.form-to-pdf input[type="file"]::-webkit-file-upload-button:hover {
    background-color: #ffffff; /* Darker blue on hover */
    border-color: #ffffff;
}

/* For non-WebKit browsers */
.form-to-pdf input[type="file"] {
    cursor: pointer; /* Pointer cursor on hover */
    background-color: white;
    margin-bottom: 10px;
}
.form-to-pdf input[type="checkbox"] {
    margin-right: 10px; /* Space between the checkbox and text */
}

/* Space between checkbox and label */
.form-to-pdf label input[type="checkbox"] {
    margin-top: 20px; /* Add some space above the checkbox */
}
.form-to-pdf input[type="checkbox"]:checked {
    background-color: #28a745; /* Green background */
    border-color: #28a745; /* Optional: Green border */
}

/* Responsive Design */
@media (max-width: 600px) {
    .form-to-pdf {
        margin: 10px; /* Reduce margin for smaller screens */
        margin-top: 30px;
        padding: 15px; /* Reduce padding */
    }

    .form-to-pdf h2 {
        font-size: 20px; /* Smaller title font size */
    }

    .form-to-pdf label {
        font-size: 14px; /* Smaller font size for labels */
    }

    .form-to-pdf input[type="text"],
    .form-to-pdf input[type="date"],
    .form-to-pdf input[type="number"],
    .form-to-pdf input[type="email"], /* Added for email */
    .form-to-pdf input[type="password"], /* Added for password */
    .form-to-pdf select,
    .form-to-pdf button,
    .form-to-pdf textarea {
        font-size: 14px; /* Smaller font size for inputs, buttons, and textarea */
    }
    
    .form-to-pdf .submit-button {
        font-size: 14px; /* Smaller font size for the submit button */
    }

    .form-to-pdf .add-resort-button,
    .form-to-pdf .remove-resort-button {
        font-size: 12px; /* Smaller font size for action buttons */
    }
    .form-to-pdf select:focus {
        background-color: #e0e0e0; /* Change to desired focus color */
        border-color: #02ac4c; /* Change border color on focus */
    }
    
    /* Change the color of the options when the select is open */
    .form-to-pdf select option {
        background-color: rgb(2, 115, 64); /* Background color for options */
        color: #ffffff; /* Text color for options */
    }
   

}
